<template>
  <v-form ref="form" class="platform-full-form" @submit.prevent="handleSave">
    <v-container class="platform-full-form__container" fluid>
      <v-row>
        <v-col cols="12">
          <h6 class="text-h6">{{ $trans("general_settings_title") }}</h6>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <c-language-select
            v-model="form.language"
            :label="$trans('c_language_select_label')"
            :disabled="loading"
          />
        </v-col>
        <v-col cols="12" md="3">
          <c-time-zone-autocomplete
            v-model="form.time_zone"
            :label="$trans('c_time_zone_autocomplete_label')"
            :disabled="loading"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="preferredPhoneCountry"
            :placeholder="$trans('autocomplete_hint')"
            :items="countries"
            item-value="iso2"
            :label="$trans('area_code')"
            :filter="customPhoneFilter"
            outlined
            :disabled="loading"
            :rules="[rules.required]"
          >
            <template #no-data>
              <div class="v-subheader theme--light">
                {{ $trans("nothing_found_here") }}
              </div>
            </template>
            <template #selection="{ item }">
              <span>{{ item.showDialCode }}</span>
            </template>
            <template #item="{ item }">
              <span>{{ item.showDialCode }}</span>
              <small class="ml-2">{{ item.name }}</small>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <c-currency-select
            v-model="form.currency"
            :label="$trans('c_currency_select_label')"
            :disabled="loading"
          />
        </v-col>
      </v-row>
      <v-row v-if="loggedUserIsAdmin" no-gutters>
        <v-col>
          <v-checkbox
            v-model="form.app_dashboard_hide_onboarding"
            hide-details
            :disabled="loading"
            color="primary"
            :label="$trans('disable_configuration')"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-checkbox
            v-model="form.replace_invalid_sms_characters"
            hide-details
            :disabled="loading"
            color="primary"
            :label="$trans('replace_invalid_sms_characters_checkbox')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h6 class="text-h6">{{ $trans("employees_settings_title") }}</h6>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-checkbox
            v-model="form.app_employees_locale_enabled"
            hide-details
            :disabled="loading"
            color="primary"
            :label="$trans('app_employees_locale_enabled_title')"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-checkbox
            v-model="form.app_employees_time_zone_enabled"
            hide-details
            :disabled="loading"
            color="primary"
            :label="$trans('app_employees_time_zone_enabled_title')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h6 class="text-h6">{{ $trans("bookings_settings_title") }}</h6>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-checkbox
            v-model="form.create_booking_email_verification_required"
            hide-details
            :disabled="loading"
            color="primary"
            :label="
              $trans('create_booking_email_verification_required_checkbox')
            "
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="font-weight-medium">
          {{ $trans("booking_payment_urls_question") }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.booking_success_url"
            :label="$trans('subscription_success_url')"
            :hint="$trans('subscription_success_url_example')"
            persistent-hint
            outlined
            validate-on-blur
            :rules="
              form.booking_success_url ? [rules.url, rules.maxChars(255)] : []
            "
            :disabled="loading"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.booking_cancel_url"
            :label="$trans('subscription_cancel_url')"
            :hint="$trans('subscription_cancel_url_example')"
            persistent-hint
            outlined
            validate-on-blur
            :rules="
              form.booking_cancel_url ? [rules.url, rules.maxChars(255)] : []
            "
            :disabled="loading"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            color="primary"
            depressed
            type="submit"
            :loading="loading"
            :disabled="loading"
          >
            {{ $trans("save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import CLanguageSelect from "./components/CLanguageSelect.vue";
import CCurrencySelect from "./components/CCurrencySelect.vue";
import CTimeZoneAutocomplete from "./components/CTimeZoneAutocomplete.vue";
import getCountries from "@/lib/calendesk-js-library/forms/countries";
import {
  maxChars,
  required,
  url,
} from "@/lib/calendesk-js-library/forms/validators";
import {
  customPhoneFilter,
  pushEvent,
} from "@/lib/calendesk-js-library/tools/helpers";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue";

export default {
  name: "PlatformFullForm",
  components: {
    CalendeskInformationMessage,
    CLanguageSelect,
    CCurrencySelect,
    CTimeZoneAutocomplete,
  },
  mixins: [sharedActions],
  data() {
    return {
      rules: {
        required,
        url,
        maxChars,
      },
      loading: false,
      countries: getCountries(),
      preferredPhoneCountry: this.$config("preferred_phone_countries")
        ? this.$config("preferred_phone_countries")[0]
        : null,
      form: {
        language: this.$config("language"),
        currency: this.$config("currency"),
        time_zone: this.$config("time_zone"),
        booking_success_url: null,
        booking_cancel_url: null,
        replace_invalid_sms_characters: false,
        app_dashboard_hide_onboarding: false,
        app_employees_time_zone_enabled: false,
        app_employees_locale_enabled: false,
        create_booking_email_verification_required: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      adminConfiguration: "setup/getAdminConfiguration",
    }),
  },
  mounted() {
    this.reloadForm();
  },
  methods: {
    ...mapActions({
      updateConfiguration: "setup/updateConfiguration",
    }),
    reloadForm() {
      if (this.adminConfiguration) {
        this.form.booking_success_url =
          this.adminConfiguration.booking_success_url;
        this.form.booking_cancel_url =
          this.adminConfiguration.booking_cancel_url;
        this.form.replace_invalid_sms_characters =
          this.adminConfiguration.replace_invalid_sms_characters;
        this.form.app_dashboard_hide_onboarding =
          this.adminConfiguration.app_dashboard_hide_onboarding;
        this.form.app_employees_time_zone_enabled =
          this.adminConfiguration.app_employees_time_zone_enabled;
        this.form.app_employees_locale_enabled =
          this.adminConfiguration.app_employees_locale_enabled;
        this.form.create_booking_email_verification_required =
          this.adminConfiguration.create_booking_email_verification_required;
      }
    },
    handleSave() {
      if (this.$refs.form.validate()) {
        pushEvent("savePlatformSettings");

        this.loading = true;
        this.updateConfiguration({
          language: this.form.language,
          currency: this.form.currency,
          time_zone: this.form.time_zone,
          preferred_phone_countries: [this.preferredPhoneCountry],
          booking_success_url: this.form.booking_success_url,
          booking_cancel_url: this.form.booking_cancel_url,
          replace_invalid_sms_characters: this.form
            .replace_invalid_sms_characters
            ? 1
            : 0,
          app_dashboard_hide_onboarding: this.form.app_dashboard_hide_onboarding
            ? 1
            : 0,
          app_employees_time_zone_enabled: this.form
            .app_employees_time_zone_enabled
            ? 1
            : 0,
          app_employees_locale_enabled: this.form.app_employees_locale_enabled
            ? 1
            : 0,
          create_booking_email_verification_required: this.form
            .create_booking_email_verification_required
            ? 1
            : 0,
        })
          .then(() => {
            this.loading = false;
            successNotification("update_success");
          })
          .catch((error) => {
            this.loading = false;
            errorNotification("operation_failed", error);
          });
      } else {
        errorNotification("form_is_invalid", {}, false);
      }
    },
    customPhoneFilter,
  },
};
</script>
